/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import React from "react";
import {Billing} from "../components/Billing";

const Style = styled.div`
height: 100%;
`

export function BillingPage(){

    return <Style>
            <Billing/>
    </Style>
}

