import React from "react";
import {PartnerForgotPassword} from "@front-component";
import styled from "@emotion/styled";
import {css} from "@emotion/react";

const Style: any = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  color:#7B7B7B;
  flex-direction: ${(props: any) => (props.imageOnLeft ? "row-reverse" : "row")};

  h1{
    font-weight: 600;
    font-size: 25px;
    line-height: 34px;
    color: #000000;
    text-align: center;
  }

  .image-container{
    max-width: 50%;
    flex: 1 1 0%;
    min-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    background: #F3F5F7;
    align-self: stretch;
  }
  .image-container svg {
    width: 70%;
    margin: auto;
  }

  @media screen and (max-width: 768px) {
    .image-container{
      display: none;
    }
  }

`;
export function ForgotPasswordPage(){
    return <Style imageOnLeft={false}>
        <div
            style={{
                flex: "2 0 200px",
                alignItems: "center",
                textAlign: "center",
                maxHeight: "100%",
                overflow: "auto",
            }}
        >
            <div>
                <div css={css`text-align:center`}>
                    <PartnerForgotPassword/>
                </div>
            </div>
        </div>
    </Style>
}