/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import axios from "axios";
import React, {useContext, useEffect} from "react";
import {Billing} from "../components/Billing";
import {AxiosContext} from "@front-component";

const Style = styled.div`
height: 100%;
`

export function BillingCancelPage(){
    const {api}= useContext(AxiosContext)
    async function load(){
        const data = await api.get("/checkout/cancel")
        window.location.href = "/brand-protection/edit/"
    }
    useEffect(() =>{
        load().then()
    },[])

    return <Style>
            <Billing/>
    </Style>
}