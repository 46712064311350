import React, { useContext, useEffect, useState} from "react";
import {loadStripe} from '@stripe/stripe-js';
import {AnimatedCheck, AxiosContext} from "@front-component";
import styled from "@emotion/styled";
import {Button, Card} from "antd";
import {useLoading} from "@front-component";

const Style = styled(Card)`
  text-align: center;
  margin: 100px auto auto;
  width: 300px;
`
export function Checkout(){
    const {api} = useContext(AxiosContext)
    const {doAction,loading} = useLoading(true)
    const [sessionId,setSessionId] = useState(undefined as any)


    useEffect(()=>{doAction(async () => {
            const session = (await api!.get("/partner/checkout/session")).data
            setSessionId(session.sessionId)
        }
    )},[])

    async function checkout(){
        const config = (await api!.get("/checkout/config")).data
        const stripePromise = await loadStripe(config.publicKey);
        await stripePromise.redirectToCheckout({
            sessionId: sessionId
        })
    }

    return <Style>
        <AnimatedCheck/>
        <div>
        <h1>Congratulations!<br/> Your GetRank registration is now complete.</h1>
            The last step is to add your billing information.<br/>
            Please click the button below to proceed.
        <br/>
        <br/>
        <Button block loading={loading} type="primary" onClick={()=> doAction(async () => await checkout())}>Add billing information</Button>
        </div>
        </Style>

}
