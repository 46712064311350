/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {Empty} from "antd";
// @ts-ignore
import {navigate, useRoutes, usePath} from 'hookrouter';
import React, {Fragment, useContext, useEffect, useState} from 'react';
import './App.css';
import {AdWordsStrictLoading, GoogleLogout} from "@front-component";
import {BillingCancelPage} from "./page/BillingCancelPage";
import {BillingPage} from "./page/BillingPage";
import {BillingSuccessPage} from "./page/BillingSuccessPage";
import {Checkout} from "./components/Checkout";
import {PageContainer} from "./components/common/PageContainer";
import {UserContext, UserContextProvider} from "@front-component";
import {LoginPage} from "./page/LoginPage";
import {PartnerSettingsPage} from "./page/PartnerSettingsPage";
import {PartnerSubscribersListPage} from "./page/PartnerSubscribersListPage";
import {HelpPage} from "./page/HelpPage";
import {SettingsPage} from "./page/SettingsPage";
import {AddPartnerSubscriberPage} from "./page/AddPartnerSubscriberPage";
import {SignupPage} from "./page/SignupPage";
import {AxiosContextProvider, useLoading, useUser} from "@front-component";
import axios from "axios";
import {LogoutPage} from "./page/LogoutPage";
import {ForgotPasswordPage} from "./page/ForgotPasswordPage";
import {BeforeLoginPageContainer} from "./components/common/BeforeLoginPageContainer";
import {SettingsChangePasswordPage} from "../../front/src/page/SettingsChangePasswordPage";



function App() {
    const api = axios.create({
        baseURL: "/api",
        withCredentials: true
    } as any)
    return <AxiosContextProvider api={api}>
        <AppRoot/>
    </AxiosContextProvider>
}

function AppRoot() {
    const [user, setUser] = useState(undefined as any)
    const {doAction, loading} = useLoading(true)
    const {loadMe} = useUser({user, setUser, reset: ()=>{}});

    useEffect(() => {doAction(async () => {
        console.log("redraw before setUser")
        await loadMe()
        console.log("redraw efter setUser")
    })}, [])

    return <AdWordsStrictLoading loading={loading}>
        <UserContextProvider user={user}>
            <AppContent/>
        </UserContextProvider>
    </AdWordsStrictLoading>
}

function AppContent() {
    const {user} = useContext(UserContext);
    const currentPath = usePath();

    let routes:any = {
        '/': () => {
            if(user){
                navigate("/accounts")
            }
            return <LoginPage/>
        },
        '/signup': () => <BeforeLogin><SignupPage/></BeforeLogin>,
        '/login': () => <BeforeLogin><LoginPage/></BeforeLogin>,
        '/logout': () => <BeforeLogin><LogoutPage/></BeforeLogin>,
        '/settings/change-password': () => <AfterLogin><ForgotPasswordPage/></AfterLogin>,
        '/forgot-password': () => <BeforeLogin><ForgotPasswordPage/></BeforeLogin>,
        '/settings': () => <AfterLogin><PartnerSettingsPage/></AfterLogin>,
        '/accounts': () => <AfterLogin><PartnerSubscribersListPage/></AfterLogin>,
        '/accounts/add': () => <AfterLogin><AddPartnerSubscriberPage/></AfterLogin>,
        '/help': () => <AfterLogin><HelpPage/></AfterLogin>,
        '/checkout': () => <AfterLogin><Checkout/></AfterLogin>,
        '/billing': () => <AfterLogin><BillingPage/></AfterLogin>,
        '/billing/success': () => <AfterLogin><BillingSuccessPage/></AfterLogin>,
        '/billing/cancel': () => <AfterLogin><BillingCancelPage/></AfterLogin>,
        '/brand-protection/monitor-competitors': () => <AfterLogin><PartnerSubscribersListPage/></AfterLogin>,
    };

    if(user && !user?.account?.orga?.subscription){
        routes = {
            '/signup': () => <BeforeLogin><SignupPage/></BeforeLogin>,
            '/login': () => <BeforeLogin><LoginPage/></BeforeLogin>,
            '/logout': () => <BeforeLogin><LogoutPage/></BeforeLogin>,
            '/forgot-password': () => <BeforeLogin><ForgotPasswordPage/></BeforeLogin>,
            '*':() =><AfterLogin><Checkout/></AfterLogin>,
        }
    }
    const routeResult = useRoutes(routes);
    return (
        <div className="App">
            {routeResult || <Empty/>}
        </div>
    );
}


export default App;

function AfterLogin({children}) {
    const {user, setUser} = useContext(UserContext)
    console.log("redraw AfterLogin")
    useEffect(() => {
        try {
            // @ts-ignore
            $crisp.push(["set", "user:email", [user?.email]])
            // @ts-ignore
            $crisp.push(["set", "user:nickname", [user?.firstName ? user?.firstName + " " + user?.lastName : user?.email]])
            //check if stripe connected and email validated if not redirect to /billing
        } catch (e) {
            console.log(e)
        }
    },[])
    return <PageContainer>
            <Fragment>
                {children}
            </Fragment>
    </PageContainer>
}
function BeforeLogin({children}) {
    return <BeforeLoginPageContainer>
        <Fragment>
            {children}
        </Fragment>
    </BeforeLoginPageContainer>
}

function OnLogin({children}) {
    console.log("redraw OnLogin")
    return <PageContainer>
        {children}
    </PageContainer>
}

