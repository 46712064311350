import React, {Fragment, useContext, useState} from 'react';
import {Form, Input, Button, Modal, Select, message, notification} from 'antd';
import {PartnerAccountAPI} from "@backend-lib";
import {AxiosContext, AxiosContextType, useLoading, UserContext} from "@front-component";
import {ModalContextReady} from "../ModalContextReady";

interface Subscriber {
    id: number;
    name: string;
    emails: string[];
    subscription: string;
    keywords: string[];
}

const { Option } = Select;

function getKeywordLimit(subscription: string) {
    return (subscription === '3 keywords' || subscription.includes("partner_3")) ? 3 : 10;
}

export function AddPartnerSubscriber() {
    const {api} = useContext<AxiosContextType>(AxiosContext);
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const {loading,doAction} = useLoading()
    const {user} = useContext(UserContext);


    const handleFormSubmit = (values: Subscriber)=>doAction(async () => {
        try {
            if(values?.emails?.includes(user!.email)){
                Modal.error({
                    title:'You can\'t add yourself as a subscriber',
                    content:<div>
                    If you want to add yourself as a subscriber and you are using Gmail, you can:<br/>
                    <ul>
                        <li>Instead of "{user?.email}"</li>
                        <li>Use `{user?.email.split("@")?.[0]}<strong>+1</strong>@{user?.email.split("@")?.[1]}`</li>
                        <li>This will sent alerts to "{user?.email}"</li>
                    </ul>
                </div>})
                return
            }
            await PartnerAccountAPI.getInstance(api!).addAccount(values)
            // Show confirmation modal
            setIsModalVisible(true);
        } catch (e: any) {
            notification.error({
                message: "Unable to create account",
                description: <Fragment>{e.message}</Fragment>,
            });
        }
    });

    const handleModalOk = () => {
        // Handle modal OK button click
        setIsModalVisible(false);
        form.resetFields();
    };

    const handleModalCancel = () => {
        // Handle modal Cancel button click
        setIsModalVisible(false);
        form.resetFields();
        window.location.href = "/accounts"
    };

    const handleSubscriptionChange = (value: string) => {
        // Reset keywords field when subscription changes
        form.setFieldsValue({ keywords: [] });
    };

    const validateEmails = (_: any, value: string[]) => {
        // Validate that all emails are valid
        const emailRegex = /^[\w\+-.]+@([\w-]+\.)+[\w-]{2,4}$/;

        for (const email of value) {
            if (!emailRegex.test(email)) {
                return Promise.reject('Invalid email format');
            }
        }

        return Promise.resolve();
    };

    const validateKeywords = (_: any, value: string[],subscription: string) => {
        // Validate keyword limit based on subscription
        //const subscription = form.getFieldValue('subscription');
        const keywordLimit = getKeywordLimit(subscription);

        if (value.length > keywordLimit) {
            return Promise.reject(`Please enter up to ${keywordLimit} keyword(s)`);
        }

        return Promise.resolve();
    };

    return (
        <Form form={form} onFinish={handleFormSubmit} layout="vertical">
            <Form.Item
                name="name"
                label="Subscriber Name or Company"
                rules={[{ required: true, message: 'Please enter a name' }]}
            >
                <Input placeholder="Apple" />
            </Form.Item>

            <Form.Item
                name="emails"
                label={<b>Enter The Email Associated With The Google Ads Account</b>}
                rules={[
                    { required: true, message: 'Please enter emails' },
                    { validator: validateEmails },
                ]}
            >
                <Select
                    mode="tags"
                    placeholder="tim@apple.com, steve@apple.com"
                    style={{ width: '100%' }}
                    tokenSeparators={[',']}
                />
            </Form.Item>

            <Form.Item
                name="subscription"
                label="Select subscription"
                rules={[{ required: true, message: 'Please select a subscription' }]}
            >
                <Select
                    placeholder="Select subscription"
                    onChange={handleSubscriptionChange}
                >
                    <Option value="getrank_partner_3_monthly_1">3 keywords</Option>
                    <Option value="getrank_partner_10_monthly_1">10 keywords</Option>
                </Select>
            </Form.Item>

            <Form.Item
                name="keywords"
                label="Enter keywords for brand monitoring"
                rules={[
                    ({ getFieldValue }) => ({
                        validator: (_, value) => {
                            const subscription = getFieldValue('subscription');
                            return validateKeywords(null, value, subscription);
                        },
                    }),
                ]}
                dependencies={['subscription']}
            >
                <Select
                    mode="tags"
                    placeholder="key1, key2, key3"
                    style={{ width: '100%' }}
                    tokenSeparators={[',']}
                />
            </Form.Item>

            <Form.Item shouldUpdate>
                {() => (
                    <Button loading={loading} type="primary" htmlType="submit" disabled={!form.getFieldValue('subscription')}>
                        Add Subscriber
                    </Button>
                )}
            </Form.Item>

            <ModalContextReady
                open={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                cancelText={"No"}
                okText={"Yes"}
                title="Confirmation"
            >
                <p>Congratulations! Subscriber added. Do you want to add a new subscriber?</p>
            </ModalContextReady>
        </Form>
    );
};