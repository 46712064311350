/** @jsxRuntime classic */
/** @jsx jsx */
import {css,jsx} from '@emotion/react';
import styled from '@emotion/styled';
import React, {useContext} from "react";
import {Logo} from "@front-component";
import {LoginImage} from "@front-component";
import {PartnerLogin} from "@front-component";
import {Button, Divider, Form} from "antd";

const Style: any = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  color: #7B7B7B;
  flex-direction: ${(props: any) => (props.imageOnLeft ? "row-reverse" : "row")};

  h1 {
    font-weight: 600;
    font-size: 25px;
    line-height: 34px;
    color: #000000;
    text-align: center;
  }

  .image-container {
    max-width: 50%;
    flex: 1 1 0%;
    min-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    background: #F3F5F7;
    align-self: stretch;
  }

  .image-container svg {
    width: 70%;
    margin: auto;
  }

  @media screen and (max-width: 768px) {
    .image-container {
      display: none;
    }
  }

  .logo {
    margin: 2em;
  }

  .ant-card {

  }

  .cgu {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #7B7B7B;
  }

`;

export function LoginPage() {
    return <Style imageOnLeft={false}>
        <div
            style={{
                flex: "2 0 200px",
                alignItems: "center",
                textAlign: "center",
                maxHeight: "100%",
                overflow: "auto",
            }}
        >
            <div>
                <div css={css`text-align: center;width: 300px; margin:auto`}>
                    <h3>Channel Partner Signup</h3>
                    <Form.Item>
                        <Button size={"large"}  block href={"/signup"}>Signup now!</Button>
                    </Form.Item>
                    <br/>
                    <Divider/>
                    <h3>Channel Partner Login</h3>
                    <br/>
                    <PartnerLogin/>
                </div>
            </div>
        </div>
        <div className={"image-container"} style={{maxWidth: "50%", flex: "1 1 50%"}}>
            <LoginImage/>
        </div>
    </Style>
}
