import React, {Fragment, useContext, useState} from 'react';
import {Button, Modal, notification, Popconfirm, Table, Tag} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {EditFilled} from "@ant-design/icons";
import {AccountDTO, PartnerAccountAPI, ProtectionDTO} from "@backend-lib";
import {AxiosContext, AxiosContextType, UserContext} from "../../context";
import {EditAccount} from "../subscribers";
import {ModalContextReady} from "../ModalContextReady";
import _ from "lodash";

interface AccountTableRow {
    id:string
    key: number
    name: string
    emails:string
    createdAt: Date
    subscription: string
    protection?: ProtectionDTO,
    usage: number,
    limit: number
}

export function PartnerSubscribersList({accounts=[]}:{accounts:AccountDTO[]}){

    const {api} = useContext<AxiosContextType>(AxiosContext);
    const {user} = useContext(UserContext);
    const [companyToEdit, setCompanyToEdit] = useState<ProtectionDTO|undefined>(undefined)
    function editProtection(protection: ProtectionDTO) {
        setCompanyToEdit(protection)
    }

    async function deleteProtection(protection: ProtectionDTO) {
        try {
            if(protection.account.users?.flatMap(u=>u.email).includes(user?.email||"")){
                throw new Error("You can't delete yourself")
            }
            await PartnerAccountAPI.getInstance(api!).deleteAccount(protection.account.id)
            notification.success({
                message: "Subscriber deleted",
            })
            setTimeout(()=>{
                window.location.reload()
            },1000)
        } catch (e: any) {
            console.error("error",e)
            notification.error({
                message: "Unable to delete subscriber",
                description: <Fragment>{e.message}</Fragment>,
            });
        }
    }

    let accountsRow: any[] = []
    for(const account of accounts){
        for(const protection of account.protections){
            accountsRow.push({
                id: account.id+"",
                protection: protection,
                key: account.id,
                name: account.name!,
                createdAt: new Date(account.createdAt!),
                subscription: account.partnerSubscription!,
                usage: protection?.keywordsCriteria?.length ,
                emails: protection?.account?.users?.map(u=>u.email).join(", "),
                limit: (account.partnerSubscription?.includes("partner_3")?"3":"10")
            })
        }

    }
    accountsRow = _.sortBy(accountsRow, (a) => a.protection?.account?.users?.map((u:any)=>u.email).join(", "))

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const columns: ColumnsType<AccountTableRow> = [
        {
            title: 'Active Date',
            dataIndex: 'createdAt',
            render: (date: Date) => date?.toLocaleDateString(),
        },
        {
            title: 'Email',
            dataIndex: 'emails'
        },
        {
            title: 'Subscription',
            dataIndex: 'subscription',
            render: (text: string) => <Fragment>{text?.includes("partner_3")?"3 keywords":"10 keywords"}</Fragment>,
        },
        {
            title: 'Usage',
            dataIndex: 'usage',
            render: (usage: number, row) => <Tag color={usage/row.limit>0.5?"orange":"green" }>{usage+"/"+row.limit}</Tag>,
        },
        {
            title: 'Action',
            render: (text: string, record: AccountTableRow) => (<div>
                <Button type="primary" icon={<EditFilled/>} onClick={() => editProtection(record.protection!)}>
                    Edit
                </Button>
                <Popconfirm title={<div>
                    <h1>Are you sure?</h1>
                    <div>Deleting this subscriber will remove all their data from the system <br/>
                        and all other alert linked to <strong>{record.emails}</strong>.
                    <br/>
                    <br/></div>


                </div>} onConfirm={()=>deleteProtection(record.protection!)}
                            okButtonProps={{danger: true}}
                            okText="Yes"
                            cancelText="No">
                <Button type="primary" danger style={{marginLeft: 10}}>
                    Delete
                </Button>
                </Popconfirm>
            </div>)
        },
    ];

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[]) => setSelectedRowKeys(selectedRowKeys),
    };

    return (
        <div>
            {companyToEdit &&
                <ModalContextReady footer={<Fragment></Fragment>} open={true}
                                   onCancel={()=>
                                       window.location.reload()}>
                    <EditAccount protection={companyToEdit!}
                                 handleModalOk={()=>{
                        window.location.reload()
                    }}  />
                </ModalContextReady>
            }
            <Table rowKey="id" dataSource={accountsRow}  columns={columns} rowSelection={rowSelection} />
        </div>
    );
};
