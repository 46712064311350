/** @jsxRuntime classic */
/** @jsx jsx */
import {css,jsx} from '@emotion/react';
import styled from '@emotion/styled';
import React, {useContext, useEffect} from "react";
import {AxiosContext, Logo, useLoading} from "@front-component";
import {LoginImage} from "@front-component";
import {PartnerLogin} from "@front-component";
import {PartnerAuthAPI} from "@backend-lib";

const Style: any = styled.div`
`;

export function LogoutPage() {
    const { api } = useContext(AxiosContext);
    const {doAction, loading,ExclusiveLoadingContainer} = useLoading()
    useEffect(() => {
      doAction(async()=>{
          try {
              await PartnerAuthAPI.getInstance(api!).logout()
          }catch (e){
              if(e?.response?.status  !== 401){
                  throw e
              }
          }
          window.location.href = "/login"
      })
    },[])
    return <Style>
        <ExclusiveLoadingContainer>

        </ExclusiveLoadingContainer>
    </Style>
}
