import React, {useContext, useEffect, useState} from "react";
import {Flex, useLoading} from "@front-component";
import {AxiosContext, AxiosContextType} from "@front-component";
import {AccountDTO, PartnerAccountAPI} from "@backend-lib";
import {PartnerSubscribersList} from "@front-component";
import {Button} from "antd";
import {FilePdfOutlined} from "@ant-design/icons";

export function PartnerSubscribersListRenderer(){
    const {doAction, loading} = useLoading()
    const [accounts, setAccounts] = useState<AccountDTO[]>([])
    const { api } = useContext<AxiosContextType>(AxiosContext);

    useEffect(() => {
        doAction(async () => {
            const data: any = await PartnerAccountAPI.getInstance(api!).getAccounts();
            setAccounts(data);
        })
    }, [])

    return <div style={{width:"100%",textAlign:"center"}}>
        <PartnerSubscribersList accounts={accounts}/>
        <Flex style={{width:"100%",textAlign:"center", marginTop: "30px"}}>
            <Button style={{margin:"auto"}} size={"large"} type={"link"} icon={<FilePdfOutlined />} href={"/GetRank Set Up for Channel Partners.pdf"} target={"_blank"}>Set Up guide for Channel Partners</Button>
        </Flex>
    </div>
}
