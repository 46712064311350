/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {LoginImage} from "@front-component";
import {Logo} from "@front-component";
import {PartnerSignup} from "@front-component";

const Style: any = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  color:#7B7B7B;
  flex-direction: ${(props: any) => (props.imageOnLeft ? "row-reverse" : "row")};

  h1{
    font-weight: 600;
    font-size: 25px;
    line-height: 34px;
    color: #000000;
    text-align: center;
  }

  .image-container{
    max-width: 50%;
    flex: 1 1 0%;
    min-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    background: #F3F5F7;
    align-self: stretch;
  }
  .image-container svg {
    width: 70%;
    margin: auto;
  }

  @media screen and (max-width: 768px) {
    .image-container{
      display: none;
    }
  }

.ant-card{

}

.cgu{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #7B7B7B;
}

`;

export function SignupPage() {
    return <Style imageOnLeft={false}>
        <div
            style={{
                flex: "2 0 200px",
                alignItems: "center",
                textAlign: "center",
                maxHeight: "100%",
                overflow: "auto",
            }}
        >
            <div>
                <div css={css`text-align:center`}>
                    <h3>Create your account</h3>
                    <br/>
                    <PartnerSignup/> {/* Use your Signup component here */}
                    <p className={"cgu"}>By signing up, you agree to our <a href={"https://getrank.io"}>Terms of Service</a><br/> and our <a
                        href={"https://getrank.io"}>Privacy Policy</a>.</p>
                </div>
            </div>
        </div>
        <div className={"image-container"} style={{minWidth: "50%", flex: "1 1"}}>
            <LoginImage/>
        </div>
    </Style>
}