/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import {LogoutOutlined, QuestionCircleOutlined, SafetyCertificateOutlined, SettingOutlined} from "@ant-design/icons"
import {Layout, Menu, Tag} from "antd";
import {Content} from "antd/lib/layout/layout";
// @ts-ignore
import React, {Fragment, useContext, useEffect} from "react";
import {Logo} from "@front-component";
import {UserContext} from "@front-component";
import {AxiosContext, AxiosContextType} from "@front-component";
// @ts-ignore
import {navigate} from "hookrouter";
import styled from "@emotion/styled";

const Style = styled.div`
  
  height: 100%;
  @media (max-width: 768px) {
    .ant-input,
    .ant-input-affix-wrapper,
    .ant-select-selector,
    .ant-alert,
    .ant-btn {
      font-size: 18px; /* Increase font size */
      padding: 12px 20px; /* Increase padding */
      height: auto; /* Auto height to accommodate increased font and padding */
    }

    /* Specific styling for the button */
    .ant-btn {
      min-width: 120px; /* Minimum width for the button */
    }
    .ant-card{
      margin-top: 0 !important;
    }
  }
`
export function BeforeLoginPageContainer({children}: { children: any }) {
    const {api} = useContext<AxiosContextType>(AxiosContext);
    const {user, setUser} = useContext(UserContext)



    const handleMenuClick = (path) => {
        navigate(path);
    };
    return <Style>
            <Logo></Logo>
            <div style={{height: 'calc( 100% - 40px)'}}>
                {children}
            </div>
    </Style>
};
