import {UserContext, UserContextProvider} from "../index";
import {AxiosContext, AxiosContextProvider} from "../context/axios.context";
import {useContext} from "react";
import {ConfigProvider, Modal, ModalProps} from "antd";

/**
 * This component is used to wrap the modal content with the required context providers.
 * @param props
 * @constructor
 */
export function ModalContextReady(props:ModalProps) {
  const {user} = useContext(UserContext)
  const {api} = useContext(AxiosContext)
  const {theme} = useContext(ConfigProvider.ConfigContext)
    return <Modal {...props}>
      <AxiosContextProvider api={api!}>
        <UserContextProvider user={user}>
          <ConfigProvider theme={theme}>
            {props?.children}
          </ConfigProvider>
        </UserContextProvider>
      </AxiosContextProvider>
    </Modal>;
}
