/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import {LogoutOutlined, QuestionCircleOutlined, SafetyCertificateOutlined, SettingOutlined} from "@ant-design/icons"
import {Layout, Menu, Tag} from "antd";
import {Content} from "antd/lib/layout/layout";
// @ts-ignore
import React, {Fragment, useContext, useEffect} from "react";
import {Logo} from "@front-component";
import {UserContext} from "@front-component";
import {AxiosContext, AxiosContextType} from "@front-component";
// @ts-ignore
import {navigate} from "hookrouter";
import styled from "@emotion/styled";

const Style = styled.div`
  
  @media (max-width: 768px) {
    .ant-input,
    .ant-input-affix-wrapper,
    .ant-select-selector,
    .ant-alert,
    .ant-btn {
      font-size: 18px; /* Increase font size */
      padding: 12px 20px; /* Increase padding */
      height: auto; /* Auto height to accommodate increased font and padding */
    }

    /* Specific styling for the button */
    .ant-btn {
      min-width: 120px; /* Minimum width for the button */
    }
    .ant-menu {
      display: flex;
      min-width: 0;
    }
    .ant-layout{
      display: block;
      width: 100% !important;
    }
    aside{
      width: 100% !important;
      max-width: 100% !important;
      min-width: 100% !important;
      padding: 5px;
    }
    .ant-card{
      margin-top: 0 !important;
    }
  }
`
export function PageContainer({children}: { children: any }) {
    console.log("redraw GooglePageContainer")
    const {api} = useContext<AxiosContextType>(AxiosContext);
    const {user, setUser} = useContext(UserContext)

    const menu = (
        <Style>
            {user && <Tag color="green">{"Google: " + user?.account?.selectedGoogleCustomer?.descriptiveName}</Tag>}
            {!user && <Tag color="orange">In progress</Tag>}
            {user &&<Menu>
                <Menu.Item>
                    <a target="_blank" rel="noopener noreferrer" href="/logout">
                        Disconnect
                    </a>
                </Menu.Item>
            </Menu>
            }
        </Style>
    );

    const handleMenuClick = (path) => {
        navigate(path);
    };
    return <Style>
        <Layout>
            <Layout.Sider theme={"light"}>
                <Logo></Logo>
                {user &&<Menu
                    defaultSelectedKeys={['/accounts']}
                    selectedKeys={[location.pathname]}
                    style={{height:"calc( 100% - 100px)"}} mode="vertical" >
                    <Menu.Item
                        key="/settings"
                        icon={<SafetyCertificateOutlined/>}
                        onClick={() => handleMenuClick('/settings')}
                    >
                        Account
                    </Menu.Item>
                    <Menu.Item
                        key="/accounts"
                        icon={<SettingOutlined/>}
                        onClick={() => handleMenuClick('/accounts')}
                    >
                        Subscribers
                    </Menu.Item>
                    <Menu.Item
                        key="/accounts/add"
                        icon={<SettingOutlined/>}
                        onClick={() => handleMenuClick('/accounts/add')}
                    >
                        Add subscribers
                    </Menu.Item>
                    <Menu.Item
                        key="/help"
                        icon={<QuestionCircleOutlined/>}
                        onClick={() => handleMenuClick('/help')}
                    >
                        Help
                    </Menu.Item>
                    <Menu.Item
                        key="/logout"
                        icon={<LogoutOutlined/>}
                        onClick={() => window.location.href='/logout'}
                    >
                        Logout
                    </Menu.Item>
                </Menu>}
            </Layout.Sider>
            <Layout>
                <Content style={{padding: '5px'}}>
                    {children}
                </Content>
            </Layout>
        </Layout>
    </Style>
};
