/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled';
import {css, jsx} from '@emotion/react';
import {Card} from "antd";
import axios from "axios";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {AxiosContext} from "@front-component";

const Style = styled.div`
background-color: #FAFBFB;
padding-top: 200px;
min-height:100%;

.ant-card-head {
border: none;
}

.ant-card{
text-align:center; 
margin: auto;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
padding-bottom: 3em;
}

.ant-card-body{
  color: #3F3F3F;
}

`

export function Billing(){
    const {api}= useContext(AxiosContext)
    const [subscription,setSubscription] = useState(undefined)

    async function load(){
        const subscription = (await api.get("/bank/subscription")).data
        setSubscription(subscription)
    }
    useEffect(() =>{
        load().then()
    },[])

    function handleOnChangeLevel() {

    }

    return (
            <Style>
                <Card css={css`
max-width: 450px; 
`}
                      title={"Simple pricing to protect your brand"}  bordered={false}>
                <p>30-Day Money-Back Guarantee</p>
                    {subscription?.plan &&
                    <Card css={css`
max-width: 250px; 
`} bordered={false}
                          title={<Fragment>
                              <div>Single brand</div>
                              <div>${(subscription?.plan.metadata.monthlyAmount || 0) / 100} /month</div>
                          </Fragment>} extra={""}
                    >
                        <ul css={css`text-decoration: none;list-style-type:none;padding: 0`}>
                            <li><b>1</b> brand protected</li>
                            <li>Google Ads monitor</li>
                            <li>Google Ads launcher</li>
                        </ul>
                    </Card>
                    }
                </Card>
            </Style>
    )
}

