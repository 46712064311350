import styled from "@emotion/styled";
import {Alert, Button, Card} from "antd";

const Style = styled.div`
`
export function HelpPage(){
    return <Style>
        <Card title={"Help"} bordered={false}  >
            <Alert type={"info"} message={"Below are resources from GetRank to help you manage your account and subscribers"}/>
            <br/>
            <Button type={"link"} target={"_blank"} href="https://www.getrank.io/free-ad-check">Do a free ad check</Button><br/>
            <Button type={"link"} href="https://partner.getrank.io/accounts/add">Add a new subscriber</Button><br/>
            <Button type={"link"} target={"_blank"} href="https://www.getrank.io/contact">Get support now</Button><br/>
            <Button type={"link"} target={"_blank"} href="https://www.getrank.io/contact">Request subscriber removal</Button><br/>
            <Button type={"link"} href="https://partner.getrank.io/settings">Change your account access</Button><br/>
            <Button type={"link"} target={"_blank"} href="https://www.getrank.io/blog">View GetRank case studies</Button><br/>
            <Button type={"link"} target={"_blank"} href="https://www.getrank.io/blog/adwords-brand-protection">How to set up a GetRank campaign</Button><br/>
        </Card>
    </Style>
}