/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {Button, Form, Input, notification} from 'antd';
import {PartnerAccountAPI} from "@backend-lib";
import React, {Fragment, useContext, useState} from "react";
import {AxiosContext, Flex, useLoading} from "@front-component";

export function PartnerSignup () {
    const {doAction, loading,ExclusiveLoadingContainer} = useLoading(false)
    const {api} = useContext(AxiosContext)

    const onFinish = (values:any)=>doAction(async () => {
        console.log('Received values of form: ', values);
        try {
            await PartnerAccountAPI.getInstance(api!).signup(values)
            window.location.href = "/checkout"
        } catch (error:any) {
            notification.error({
                message: 'Unable to signup',
                description: error.message,
            })
            return false;
        }
    });

    return <ExclusiveLoadingContainer><PartnerSignupRenderer onFinish={onFinish} /></ExclusiveLoadingContainer>

};

export function PartnerSignupRenderer ({onFinish=()=>{}}: {onFinish: any}) {
    const {api} = useContext(AxiosContext)
    const [form] = Form.useForm();
    const [additionalFieldVisible, setAdditionalFieldVisible] = useState(false);


    return (
        <Form
            form={form}
            name="signup"
            onFinish={onFinish}
            css={css`
        max-width: 350px;
        margin: 0 auto;
      `}
        >
            <Form.Item
                name="name"
                rules={[
                    {
                        required: true,
                        message: 'Please input your name!',
                    },
                ]}
            >
                <Input placeholder="Name" />
            </Form.Item>

            <Form.Item
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                ]}
                hasFeedback
            >
                <Input.Password placeholder="Password" />
            </Form.Item>


                <Form.Item style={{flex:2}} name={"email"} label={""} rules={[{type:"string",required:true}]}>
                    <Input type={"email"} placeholder={"Email"}></Input>
                </Form.Item>

            <div style={{textAlign:"left", marginBottom:5}}>Please verify your email by sending the code</div>
                <Flex>
                <Form.Item>
                    <Button onClick={async()=>{
                        if(!form.getFieldValue("email")) {
                            notification.error({
                                message: "Please fill your email first",
                            })
                            return
                        }

                        setAdditionalFieldVisible(true)
                        await PartnerAccountAPI.getInstance(api!).validateEmailCode(form.getFieldValue("email"))
                        notification.success({
                            message: "Code sent, please check your email",
                        })
                    }}>
                        Send my code
                    </Button>
                </Form.Item>
                    {additionalFieldVisible && <Form.Item dependencies={[["email"]]}  name={"code"} label={""} rules={[{type:"string",required:true}]} required={true}>
                    {form.getFieldValue("email") && <Input type={"code"} placeholder={"Code"}></Input>}
                </Form.Item>
                    }
            </Flex>

                {additionalFieldVisible && <Form.Item shouldUpdate={()=>true} >
                <Button block type="primary" htmlType="submit">
                    Signup
                </Button>
            </Form.Item>}
        </Form>
    );
}