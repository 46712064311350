import styled from "@emotion/styled";
import {AddPartnerSubscriber} from "@front-component";
import {Alert, Card} from "antd";

const Style = styled.div`
`
export function AddPartnerSubscriberPage() {
    return <Style>
        <Card title={"Add a new Subscriber"} >
            <Alert type={"info"} message={"Below is the form to add a new Subscriber"}/>
            <br/>
            <AddPartnerSubscriber/>
        </Card>
    </Style>
}