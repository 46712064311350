import styled from "@emotion/styled";
import React from "react";
import {PartnerSettingsRenderer} from "@front-component";

const Style = styled.div`
`
export function PartnerSettingsPage() {
    return <Style>
        <PartnerSettingsRenderer/>
    </Style>
}
