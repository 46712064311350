import {AxiosInstance} from "axios";
import {MeDTO} from "@backend-lib";

export class PartnerAuthAPI {
  static getInstance: (api: AxiosInstance) => PartnerAuthAPI;
  constructor(private api?:AxiosInstance){
    if(!api){
      throw new Error("No axios instance provided")
    }
  }
  public async logout():Promise<void>{
    return (await this.api!.delete("/auth/login")).data
  }
}
PartnerAuthAPI.getInstance = (api:AxiosInstance)=>{
  return new PartnerAuthAPI(api)
}
