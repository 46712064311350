import styled from "@emotion/styled";
import {Alert, Button, Card, Divider, Form, Input, notification} from "antd";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {CheckoutAPI, PartnerAccountAPI} from "@backend-lib";
import {AxiosContext, AxiosContextProvider} from "../../context";
import {useLoading} from "../../hooks";
import {Flex} from "../common";

const Style = styled.div`
max-width: 350px;
  margin: auto;
    
`
export function PartnerForgotPassword(props:{}){
    const {api} = useContext(AxiosContext)
    const [form] = Form.useForm();
    const {loading, doAction} = useLoading()

    return <Style>
        <h1>Change Account Password</h1>
        <Alert type={"info"} message={"Below are options to reset your account password"}/>
        <br/>
        <Form form={form} layout={"vertical"}  onFinish={(values)=>doAction(async ()=>{
            try {
                await PartnerAccountAPI.getInstance(api!).forgotPassword(values)
                notification.success({
                    message: "Password updated",
                });
                form.resetFields()
                setTimeout(()=>{
                    window.location.href = "/login"
                }, 1000)
            } catch (e:any) {
                notification.error({
                    message: "Unable to update password",
                    description: <Fragment>{e.message}</Fragment>,
                });
            }

        })}>
            <Flex>
            <Form.Item style={{flex:2}} label={"Email"} name={"email"} rules={[{type:"string",required:true}]}>
                <Input type={"email"}></Input>
            </Form.Item>
            <Button onClick={async()=>{
                if(!form.getFieldValue("email")){
                    notification.error({
                        message: "Please fill your email first",
                    })
                }
                await PartnerAccountAPI.getInstance(api!).forgotPasswordCode(form.getFieldValue("email"))
                notification.success({
                    message: "Code sent, please check your email",
                })
            }}>
                Get my code
            </Button>
            </Flex>
            <Form.Item help={"Please verify your email to  get the code"} label={"Code"} name={"code"} rules={[{type:"string",required:true}]} required={true}>
                <Input type={"code"}></Input>
            </Form.Item>
            <Form.Item label="New Password" name="newPassword" rules={[{ type: "string", required: true }]} required={true}>
                <Input  type="password"></Input>
            </Form.Item>
            <Form.Item
                label="New Password Confirmation"
                name="newPasswordConfirmation"
                rules={[
                    { type: "string", required: true },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('newPassword') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two passwords do not match!'));
                        },
                    }),
                ]}
            >
                <Input  type="password"></Input>
            </Form.Item>
            <Button htmlType={"submit"} loading={loading} block type={"primary"}>
                Submit
            </Button>
        </Form>
    </Style>
}