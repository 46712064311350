import {Alert, Card} from "antd";
import styled from "@emotion/styled";
import {PartnerSubscribersListRenderer} from "@front-component";

const Style = styled.div`
`
export function PartnerSubscribersListPage(){
    return <Style>
        <Card title={"Subscribers"} >
            <Alert type={"info"} message={"Below are your subscribers"}/>
            <br/>
            <PartnerSubscribersListRenderer/>
        </Card>
    </Style>
}