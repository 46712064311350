import React, {useContext} from "react";
import {Flex, useLoading, UserContext, useUser} from "@front-component";
import {Button, Form, Input} from "antd";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import styled from "@emotion/styled";

export function PartnerLogin() {
    const userCtx = useContext(UserContext);
    const {doAction, loading,ExclusiveLoadingContainer} = useLoading(false)
    const {login} = useUser(userCtx);

    const onFinish = (values: any) => doAction(async () => {
        userCtx.reset();
        const result: any = await login(values.email, values.password, values.remember);
        setTimeout(() => {
            window.location.href = "/"
        }, 100);
    });
    return <ExclusiveLoadingContainer><PartnerLoginRenderer onFinish={onFinish}/></ExclusiveLoadingContainer>

}

export function PartnerLoginRenderer({onFinish = () => {}}: { onFinish: any}){

    return <LoginStyle>
        <Form
            name="normal_login"
            className="login-form"
            initialValues={{remember: true}}
            onFinish={onFinish}
        >
            <Form.Item
                name="email"
                rules={[{required: true, message: 'Please input your Username!'}]}
            >
                <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Email"/>
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{required: true, message: 'Please input your Password!'}]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon"/>}
                    type="password"
                    placeholder="Password"
                />
            </Form.Item>
            <Flex style={{float:"right"}}>
                <Form.Item>
                    <Button type={"link"} href={"/forgot-password"}>Forgot password?</Button>
                </Form.Item>
                <Form.Item>
                    <Button size={"large"}
                            type="primary"
                            htmlType="submit"
                            block
                            className="login-form-button">
                        Log in
                    </Button>
                </Form.Item>
            </Flex>
        </Form>
    </LoginStyle>
}

const LoginStyle = styled.div`
  text-align: center;
  margin: auto;

  #components-form-demo-normal-login .login-form {
    max-width: 300px;
  }

  #components-form-demo-normal-login .login-form-forgot {
    float: right;
  }

  #components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
    float: left;
  }

  #components-form-demo-normal-login .login-form-button {
    width: 100%;
  }
`

