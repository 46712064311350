import styled from "@emotion/styled";
import {Alert, Button, Card, Divider, Form, Input, notification} from "antd";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {CheckoutAPI, PartnerAccountAPI} from "@backend-lib";
import {AxiosContext, AxiosContextProvider} from "../../context";
import {useLoading} from "../../hooks";

export function PartnerSettingsRenderer(){
    const {api} = useContext(AxiosContext)
    const {loading,doAction, ExclusiveLoadingContainer} = useLoading()

    const [billingUrl,setBillingUrl] = useState()

    useEffect(() => {
        doAction(async()=> {
           const url =  await CheckoutAPI.getInstance(api!).getBillingUrl()
            setBillingUrl(url)
        })
    }, []);

    return <Card><ExclusiveLoadingContainer>{billingUrl !== undefined && <PartnerSettings billingUrl={billingUrl}></PartnerSettings>}</ExclusiveLoadingContainer></Card>
}

const Style = styled.div`
max-width: 400px;
  margin: auto;
    
`
export function PartnerSettings(props:{billingUrl:string}){
    const {api} = useContext(AxiosContext)
    const [form] = Form.useForm();

    return <Style>
        <h1>Change Account Password</h1>
        <Alert type={"info"} message={"Below are options to reset your account password"}/>
        <br/>
        <Form form={form} layout={"vertical"}  onFinish={async (values)=>{
            try {
                await PartnerAccountAPI.getInstance(api!).updatePassword(values)
                notification.success({
                    message: "Password updated",
                });
                form.resetFields()
            } catch (e:any) {
                notification.error({
                    message: "Unable to update password",
                    description: <Fragment>{e.message}</Fragment>,
                });
            }

        }}>
            <Form.Item label={"Current Password"} name={"oldPassword"} rules={[{type:"string",required:true}]}>
                <Input type={"password"}></Input>
            </Form.Item>
            <Form.Item label="New Password" name="newPassword" rules={[{ type: "string", required: true }]}>
                <Input  type="password"></Input>
            </Form.Item>
            <Form.Item
                label="New Password Confirmation"
                name="newPasswordConfirmation"
                rules={[
                    { type: "string", required: true },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('newPassword') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two passwords do not match!'));
                        },
                    }),
                ]}
            >
                <Input  type="password"></Input>
            </Form.Item>
            <Button htmlType={"submit"} block type={"primary"}>
                Submit
            </Button>
        </Form>
        <br/>
        <Divider/>
        <br/>
        <h1>Edit Update Billing Information</h1>
        <Alert type={"info"} message={"Below are option to update your account billing information."}/>
        <br/>
        <Button block href={props.billingUrl} target={"_blank"}>Edit billing on Stripe</Button>
    </Style>
}