import {AxiosInstance} from "axios";

export class CheckoutAPI {
    static getInstance: (api: AxiosInstance) => CheckoutAPI;

    constructor(private api?: AxiosInstance) {
    }

    async getBillingUrl() {
        const response = await this.api!.get("/partner/checkout/billing-url")
        return response.data?.url
    }
}

CheckoutAPI.getInstance = (api: AxiosInstance) => {
    return new CheckoutAPI(api)
}
